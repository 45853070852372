import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './formInput.scss';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const FormInput = ({ className, id, text, placeholder, type, onChange, onBlur, onClick, inputProps, errorMessage, disabled, onKeyDown }) => {
    return (
        <div className={`form-input ${className}`}>
            <input
                required={id !== 'dir-piso'}
                type={type}
                placeholder={placeholder}
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                {...inputProps}
                onClick={() => onClick && onClick(id)}
                onKeyDown={(e) => onKeyDown && onKeyDown(e)}
            />
            <label htmlFor={id}>{text}</label>
            {errorMessage && <span><FontAwesomeIcon icon={faCircleXmark} />{' '}{errorMessage}</span>}
        </div>
    );
};

export default FormInput;
