import { useTranslation } from 'react-i18next'

import './totalPedido.scss'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import CodigoDescuento from './components/CodigoDescuento'
import { checkPresupuestoEcommerce } from '../../../../../utils'

const TotalPedido = ({ className = '', handleSelectDirEnvio }) => {
  const { t } = useTranslation()
  const checkoutInfo = useSelector(state => {
    return state.checkout.entity
  });

  const getSubTotal = () => {
    const subtotal = checkoutInfo.presupuesto.total + checkoutInfo.presupuesto.total_envio + checkoutInfo.presupuesto.total_casco;
    return parseFloat(subtotal).toFixed(2);
  }

  const getIVA = () => {
    const IVAvalue = checkoutInfo.presupuesto?.iva === 21  ? (getSubTotal() *  1.21) : 0;
    const IVA = checkoutInfo.presupuesto?.iva === 21  ? (IVAvalue - getSubTotal()) : IVAvalue;
    return parseFloat(IVA).toFixed(2); 
  }

  const getTotal = (conDescuento) => {
    if(conDescuento) {
      const totalIVA = checkoutInfo.presupuesto.total_iva;
      return parseFloat(totalIVA).toFixed(2);
    } else {
      const totalIVA = checkoutInfo.presupuesto.total_iva + checkoutInfo.presupuesto.total_descuento_iva;
      return parseFloat(totalIVA).toFixed(2);
    }
  }


  return <div className={`total-pedido ${className}`}>
        <h2>{t('carrito.pedido.title')}</h2>
        <p>
            <span>{t('carrito.pedido.subtotal')}</span>
            <span>{(getSubTotal() - checkoutInfo.presupuesto.total_envio).toFixed(2)}€</span>
        </p>
        <p>
            <span>{t('carrito.pedido.transporte')}</span>
            {checkoutInfo?.presupuesto?.total_envio === 0 && checkPresupuestoEcommerce(checkoutInfo?.presupuesto?.created_by?.username)
              ? <span className='total-pedido__no-transporte' onClick={handleSelectDirEnvio}>{t('carrito.pedido.no-transporte')}</span> 
              : <span>{(checkoutInfo.presupuesto.total_envio).toFixed(2)}€</span>}
        </p>
        <p className='total-pedido__impuestos'>
            <span>{t('carrito.pedido.impuestos')}</span>
            {
              checkoutInfo?.presupuesto?.direccion_envio_id
                ? <span>{getIVA()}€</span>
                : <span className='total-pedido__no-transporte' onClick={handleSelectDirEnvio}>{t('carrito.pedido.no-transporte')}</span>
            }
        </p>

        <CodigoDescuento checkoutInfo={checkoutInfo}/>

        <h4>
            <span>{t('carrito.pedido.total')}</span>
            <span>{getTotal(checkoutInfo.presupuesto?.vale_descuento_id)}€</span>
        </h4>
    </div>
}

TotalPedido.propTypes = {
  className: PropTypes.string
}

export default TotalPedido
